import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import Layout from '../../components/Layout'
import Img from 'gatsby-image'

export default class EventsPage extends React.Component {
  isEventPast (eventStart) {
    if (moment().diff(moment(eventStart)) > 0)
      return false
    return true
  }

  renderEvent (post) {
    return (
    <div
      className="box columns"
      key={post.id}
    >
      <div className="column is-3">
        {post.frontmatter.image != null ? <Img fluid={post.frontmatter.image.childImageSharp.fluid}/> : null}
      </div>
      <div className="column is-9 ">
        <p>
          <Link className="has-text-primary is-title-text is-size-4" to={post.fields.slug}>
            {post.frontmatter.title}
          </Link>
        </p>
        <p>
          {moment(post.frontmatter.eventStart).format("dddd, MMMM Do YYYY")}
          <br />
          {moment(post.frontmatter.eventStart).format("h:mm A")} - {moment(post.frontmatter.eventEnd).format("h:mm A")}
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={post.frontmatter.calendarLink}>
            Add to calendar
          </a>
          <br />
          <br />
          {post.frontmatter.address}
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={post.frontmatter.mapLink}>
            Directions
          </a>
          <br />
          <br />
          {post.frontmatter.description}
          <br />
          <Link className="button is-3 has-margin-top" to={post.fields.slug}>
            Learn More
          </Link>
        </p>
      </div>
    </div>
    )
  }
  render () {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    var pastEvents = []

    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content has-text-centered is-underlined">
              <h1 className="has-text-weight-bold is-size-3">Upcoming Events</h1>
            </div>
            <hr/>
            {posts
             .map(({ node: post }) => {
               if (!this.isEventPast(post.frontmatter.eventStart)) {
                 pastEvents.push(post)
                 return null
               }
               return this.renderEvent(post)
             })}
            <div className="content has-text-centered">
              <h1 className="has-text-weight-bold is-size-3">Past Events</h1>
            </div>
            <hr/>
            {pastEvents
             .map((post) => this.renderEvent(post))
            }
          </div>
        </section>
      </Layout>
    )
  }
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query EventsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "event" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date(formatString: "MMMM DD, YYYY")
            eventStart
            eventEnd
            address
            calendarLink
            mapLink
            image {
                childImageSharp {
                    # Other options include height (set both width and height to crop),
                    # grayscale, duotone, rotate, etc.
                    fluid(maxHeight: 1000) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
  }
`
